<template>
  <div class="header-container">
    <div class="row">
      <div class="content-center">
        <img
          :src="logoImg"
          @click="changeTitle('home')"
          class="img-Logo"
        />
      </div>
      <div class="content-right">
        <a-menu mode="horizontal">
          <a-menu-item key="home" @click="changeTitle('home')"> 首页 </a-menu-item>
          <a-menu-item key="productService" @click="changeTitle('productService')"> 产品与服务 </a-menu-item>
          <a-menu-item key="videoClass" @click="changeTitle('videoClass')"> 视频学堂 </a-menu-item>
          <!-- <a-menu-item key="solution">
            解决方案
          </a-menu-item> -->
          <a-menu-item key="aboutUs" @click="changeTitle('aboutUs')"> 关于我们 </a-menu-item>
          <a-menu-item key="login" style="padding-right: 0px" @click="changeTitle('login')">
            登录/注册
          </a-menu-item>
          <!-- <a-menu-item class="submenu" v-show="token" key="personInformation">
            {{personCenter||'个人中心'}}
          </a-menu-item> -->
          <!-- <a-sub-menu class="sub-menu" key="personInformation">
        <span slot="title"> {{personCenter||'个人中心'}}</span>
          <a-menu-item key="personInfo">
            个人信息
          </a-menu-item>
          <a-menu-item key="entryMaterials">
            申请入驻
          </a-menu-item>
          <a-menu-item key="setting:3">
            退出登录
          </a-menu-item>
      </a-sub-menu> -->
        </a-menu>
      </div>
      <!-- <a-col  v-show="token" >
        <div class="out">
          <button class="outBtn" @click="outLogin">退出</button>
        </div>
      </a-col> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      personCenter: "",
      token: "",
      searchValue: "", //搜索内容
      current: ["home"], // homePage首页
      logoImg: require("@/assets/smartcat/logo.svg"),
    };
  },
  watch: {
    $route(to) {
		console.log(to)
	  if(to.name == 'videoClass'){
		  return false
	  } else {
		  // this.current = [to.name];
	  }
    },
  },
  methods: {
    changeTitle(item) {
      if (item == "videoClass") {
        window.open('/videoClass', "_blank");
		
      } else {
        this.$router.replace(item)
      }
    },
    outLogin() {
      // this.$store.commit('removeToken')
      // this.$store.commit('removeName')
      // window.location.replace('/home')
      //   this.$confirm({
      //   title: '',
      //   content: '确定退出?',
      //   okText: '确定', // 自定义确定按钮文本
      //   cancelText: '取消', // 自定义取消按钮文本
      //   class:'confirm-btn',
      //   onOk() {
      //     console.log(1111);
      //   },
      //   onCancel() {
      //     console.log(222);
      //   },
      // });
    },
  },
  // created () {
  //   this.$router.push('home')
  //   // this.personCenter = this.$store.state.userName
  //   // this.token = this.$store.state.scToken
  //   // let routeKsy = sessionStorage.getItem('routeKsy')
  //   // if (routeKsy) {
  //   // }
  // }
};
</script>

<style scoped="scoped" lang="scss">
.header-container {
  width: 1200px;
  margin: auto;
  background-color: #fff;
  .row {
    display: flex;
    justify-content: space-between;
  }
  .content-center {
    cursor: pointer;
    .img-Logo {
      width: 146px;
      height: 40px;
    }
  }
  .content-right {
    // width: 500px;
    margin-left: 30px;
  }

  // 隐藏了 antdvue 导航条的底部线条 并且给了新的高度
  .ant-menu-horizontal {
    border: none;
    line-height: 63px;
    color: rgba(136, 136, 136, 1);
  }
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: rgba(0, 0, 0, 1);
    border-bottom: 2px solid transparent;
  }
  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover {
    color: rgba(136, 136, 136, 1);
    border-bottom: 2px solid transparent;
  }
  .ant-menu-horizontal > .ant-menu-item {
    font-size: 14px;
  }
  /deep/.ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: rgba(0, 0, 0, 1) !important;
  }
  .out {
    margin-left: -20px;
    margin-top: -2px;
    .outBtn {
      background-color: #fff;
      border: none;
    }
    .outBtn:hover {
      color: #000;
      cursor: pointer;
    }
  }
}
</style>
